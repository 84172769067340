'use strict';

/**
 * Check required form fields - disable\enable submit button
 * @param {HTMLElement} $submitBtn - submit button HTML element
 * @param {HTMLElement} $formWrapper  - form wrapper HTML element
 * @returns {boolean} true if valid
 */
function checkFormFieldsFilledAddressChecker($submitBtn, $formWrapper) {
    let isValid = true;

    $formWrapper.find('input, select').filter('[required]').not('[readonly]').each(function () {
        if ($(this).val() === '' || ($(this).is("[type='checkbox']") && $(this).is(':checked') === false)) {
            isValid = false;
        }
    });


    return isValid;
}

/**
 * Check required form fields - disable\enable submit button
 * @param {HTMLElement} $submitBtn - submit button HTML element
 * @param {HTMLElement} $formWrapper  - form wrapper HTML element
 * @returns {boolean} true if valid
 */
function checkFormFieldsFilled($submitBtn, $formWrapper) {
    let isValid = true;

    $formWrapper.find('input, select').filter('[required]').not('[readonly]').each(function () {
        if ($(this).val() === '' || ($(this).is("[type='checkbox']") && $(this).is(':checked') === false)) {
            $submitBtn.prop('disabled', true);
            isValid = false;
        }
    });

    if (isValid) {
        $submitBtn.prop('disabled', false);
    }

    return isValid;
}

module.exports = {
    checkFormFieldsFilled: checkFormFieldsFilled,
    checkFormFieldsFilledAddressChecker: checkFormFieldsFilledAddressChecker
};
