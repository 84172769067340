

'use strict';

// eslint-disable-next-line
let { triggerEvent } = require('../helpers/dataLayerHelper');
const { createPolygonDate } = require('telco/helpers/polygonDateHelper');
const checkFormFieldsFilled = require('telco/helpers/formHelper');
var mdulistGlbl = [];
const cache = {
    $submit: $('#adresscheck-submit'),
    $serviceAddress: $('#serviceAddress'),
    $o2check: $('#o2check'),
    $o2UnitsOptions: $('#o2UnitsOptions'),
    $o2UnitNumber: $('#o2UnitNumber'),
    $unitNumber: $('#unitNumber'),
    $o2checkWrapper: $('#js-serviceAddress-wrapper'),
    $radioBtns: $('#js-radio-btns-wrapper, #js-radio-btns-wrapper .custom-control-input'),
    $form: $('#address-form'),
    $qfModalError: $('#qf-modal-error'),
    $jsPurchase: $('#js-purchase'),
    $jsKnow: $('#js-know'),
    $instantWifi: $('#instantWifi'),
    $o2Input: $('#o2check'),
    $insertO2Script: $('#o2AppendScript')
};

/**
 * Initializes the google autocomplete service
 */
function initAutocomplete() {
    let expiry2 = new Date();
    expiry2.setDate(expiry2.getDate() - 1);
    localStorage.setItem('QFECKNOWME', '');
/**
 * Makes the address input field empty
 */
/*
    let filteredCookie = document.cookie.split(';').filter(key => {
        if (key.includes('QFWelcomeBack')) {
            return key;
        }
        return false;
    }
    );
    if (filteredCookie && filteredCookie[0]) {
        const cookie = filteredCookie[0].split('=')[1];
        if (cookie) {
            let address = cookie.split('~')[0].split('|')[1];
            if (address) {
                if ($('#o2check').length > 0) {
                    $('#o2check')[0].value = address;
                    $('#o2check').focus();
                }
            }
        }
    }
*/
}

/**
 * Prepopulates the zip, address and unit form fields using
 * values from URL parameters
 */
function prefillAddressFields() {
    if ('URLSearchParams' in window) {
        const params = new URLSearchParams(window.location.search); // not supported in IE
        const address = decodeURI(params.get('sla'));
        const unitNumber = decodeURI(params.get('unitnumber'));

        if (cache.$serviceAddress.length > 0) {
            if (address && address !== 'null') {
                cache.$serviceAddress.val(address);
            }
            if (unitNumber && unitNumber !== 'null') {
                cache.$unitNumber.val(unitNumber);
            }
        } else if (cache.$o2Input.length > 0) {
            if (address && address !== 'null') {
                cache.$o2Input.val(address);
            }
            if (unitNumber && unitNumber !== 'null') {
                cache.$o2UnitNumber.val(unitNumber);
            }
        }

        cache.$form.trigger('change');
    }
}


/**
 * Populates the hidden inputs with the given address components for O2 type ahead
 * @param {*} addressData object with adress components
 */
function fillAddressO2Inputs(addressData) {
    const {
        lat,
        long,
        streetNum,
        street,
        city,
        stateCode,
        zip,
        mdu,
        addressKey,
        stateCodeLong,
        predirection,
        postdirection,
        afstreetname,
        afstreetsuffix,
        workType,
        geo_type,
        propertyId // TO-DO: PROVIDER ID NEEDS TO BE ADDED ONCE RETURNED IN O2 CACHE
    } = addressData;

    $('#lat').val(lat);
    $('#long').val(long);
    $('#streetnumber').val(streetNum);
    $('#street').val(street);
    $('#city').val(city);
    $('#stateCode').val(stateCode);
    $('#stateCodeLong').val(stateCodeLong);
    $('#zipcode').val(zip);
    $('#countrycode').val('USA');
    $('#mdu').val(mdu);
    $('#addressId').val(addressKey);
    $('#addressKey').val(addressKey);
    $('#baseAddr').val(addressKey);
    $('#predirection').val(predirection);
    $('#postdirection').val(postdirection);
    $('#afstreetname').val(afstreetname);
    $('#afstreetsuffix').val(afstreetsuffix);
    $('#workType').val(workType);
    $('#geo_type').val(geo_type);
    $('#propertyId').val(propertyId);  // TO-DO: PROVIDER ID NEEDS TO BE ADDED ONCE RETURNED IN O2 CACHE
}

/**
 * submit ajax request
 * @param {string} url url for ajax request
 * @param {Object} addressObj address data
 */
function submitFormAjax(url) {
    $.spinner().start();
    $('.alert.alert-danger').remove(); // remove server-side error msg
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: cache.$form.serialize(),
        success: function (data) {
            const polygonDate = createPolygonDate(data.polygonEcd);
            if (data.isSmartNIDInstantIntFlow) {
                window.localStorage.setItem('flow', 'quantum - instant 360 wifi');
                window.dispatchEvent(new Event('Flow Name Set'));
            }
            if (!data.success) {
                $.spinner().stop();
                cache.$qfModalError.modal('show');
            } else if (data.copperToFiberMigration) {
                window.localStorage.setItem('flow', 'qf - migration');
                window.dispatchEvent(new Event('Flow Name Set'));
                location.href = data.redirectUrl;
            } else if (data.modal === 'prePurchase') {
                $.spinner().stop();
                location.href = data.redirectUrl;
            } else if (data.modal === 'letMeKnow') {
                $.spinner().stop();
                cache.$jsKnow.text(polygonDate);
                location.href = data.redirectUrl;
            } else if (data.modal === 'customerAttention') {
                $.spinner().stop();
                cache.$jsKnow.text(polygonDate);
                $('#qfModalAttention').modal('show');
            } else if (data.modal === 'failedToLoad') {
                $.spinner().stop();
                $('#qf-modal-error-dummy').html(data.modalContent);
                cache.$qfModalError.html($('#qf-modal-error-dummy #qf-modal-error').html()).modal('show');
                $('#qf-modal-error-dummy').empty();
            } else {
                location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            $.spinner().stop();
            cache.$qfModalError.modal('show');
            // eslint-disable-next-line
            console.log(err.responseJSON.error);
            if (err.responseJSON.redirectUrl) {
                location.href = err.responseJSON.redirectUrl;
            }
        }
    });
}

/**
 * Call our controller method to apply promotion to product
 * @param {string} affProg affProg value from createdCookie
 */
function applyPromotion(affProg) {
    if (affProg === 'amazon') {
        $.ajax({
            url: '/on/demandware.store/Sites-QFCC-Site/default/AddressChecker-EnablePromotion?affProg=' + affProg,
            type: 'post',
            dataType: 'json'
            // success: function (data) {
            //      console.log('data: ', data);
            //  }
            // error: function (err) {
            //     console.log("error is: ", err);
            // }
        });
    } else {
        $.ajax({
            url: '/on/demandware.store/Sites-QFCC-Site/default/AddressChecker-DisablePromotion',
            type: 'post',
            dataType: 'json'
        });
    }
}


/**
 * Create cookie if flow is directed from Amazon
 */
function createCookies() {
    if (window.location.href.indexOf('affprog=') !== -1) {
        var urlParams = window.location.search;
        var cookieParams = new URLSearchParams(urlParams);
        var salescode = cookieParams.get('salescode');
        $('#salescode').val(salescode);

        var affProg = cookieParams.get('affprog');

        applyPromotion(affProg);
    }
}

/**
 * Separate function to setup popovers in case will be multiple
 */
function createPopovers() {
    $('#address-check__tooltip').popover({
        html: true,
        trigger: 'hover focus',
        content: $('#address-check__tooltip-text').html()
    });
}

// eslint-disable-next-line require-jsdoc
function getCookie(cookieName) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    });
    return cookie[cookieName];
}

/**
 * 1. Retrieves geocoding data for the input adress
 * 2. parses and stores the address data in an object
 * 3. Populates the hidden input fields with the address components
 * 4. Submits the form
 * @param {Event} ev event
 */
function submitGeocodedAddress(ev) {
    ev.preventDefault();
    var qfBlockedAttr = $('#o2-unit-select-container .selectize-dropdown-content .option.selected').data('qf-blocked');
    if (qfBlockedAttr === 'QFBLOCKED') {
        $.spinner().stop();
        $('#qfModalAttention').modal('show');
        return;
    }
    const address = cache.$serviceAddress.val();
    const url = cache.$form.attr('action');
    const instantWifi = cache.$instantWifi.val() === 'true';

    if (address && !instantWifi) {
        // cache.$submit.prop('disabled', true);
    }
    let QFWelcomeBack = '';
    if ($('#serviceAddress').length > 0) {
        QFWelcomeBack = $('#serviceAddress')[0].value;
    } else if ($('#o2check').length > 0) {
        QFWelcomeBack = $('#o2check')[0].value;
    }
    let units = '';
    if ($('#unitNumber').length) {
        units = $('#unitNumber')[0].value;
    } else if ($('#o2UnitNumber').length) {
        units = $('#o2UnitNumber')[0].value;
    }
    let existingCustomer = $('#centurylink-existing-yes').prop('checked') ? 'yes' : 'no';
    let expiry = new Date();
    expiry.setDate(expiry.getDate() + 30);
    let QFECKNOWMECookie = getCookie('QFECKNOWME');
    if (!QFECKNOWMECookie || QFECKNOWMECookie === '') {
        document.cookie = `QFWelcomeBack=address|${QFWelcomeBack} ~ unit|${units} ~ ExistingCustomer|${existingCustomer}; path=/ ; expires=${expiry}`;
    }
    submitFormAjax(url);
}

/**
 * Disables the submit button if the address input is empty
 */
// function disableSubmitOnEmptyInput() {
//     cache.$serviceAddress.on('keyup', function () {
//         if ($(this).val().length === 0) {
//             cache.$submit.attr('disabled', true);
//         } else {
//             cache.$submit.attr('disabled', false);
//         }
//     });
//     cache.$serviceAddress.keyup();

//     cache.$o2check.on('keyup', function () {
//         if ($(this).val().length < 3) {
//             cache.$submit.attr('disabled', true);
//         } else {
//             cache.$submit.attr('disabled', false);
//         }
//     });
//     cache.$o2check.keyup();

//     cache.$o2UnitNumber.on('keyup', function () {
//         if ($(this).val().length < 1) {
//             cache.$submit.attr('disabled', true);
//         } else {
//             cache.$submit.attr('disabled', false);
//         }
//     });
// }

/**
 * o2VariantRequest
 */
function o2VariantRequest() {
    var endpoint = cache.$form.data('addressesbyvariantaddress-url');
    var address = $('#o2check').val();
    var o2Script = endpoint + '?addressesStartsWith=' + address + '&maxResults=1&callback=o2VariantResponse';
    if (cache.$insertO2Script.children().length > 0) {
        cache.$insertO2Script.empty();
    }
    cache.$o2UnitsOptions.selectize()[0].selectize.destroy();
    cache.$insertO2Script.append($('<script></script>').attr('src', o2Script));
}

/**
 * updateMDUUnits
 * @param {Object} element the HTML element for the address suggestion
 */
function updateMDUUnits(element) {
    var isAddressMDU = $(element).find('input').data('mdu');
    var addressInfo = $(element).find('input').data('addressinfo');
    if (isAddressMDU === 'TRUE') {
        $('#o2-unit-select-container').show();
        $('#o2-unit-input-container').hide('slow');
        var addressId = $(element).find('input').data('addressid');
        var endpoint = cache.$form.data('getmduunits-url');
        var mduScript = endpoint + '?addressId=' + addressId + '&callback=mduResponse';
        if (cache.$insertO2Script.children().length > 0) {
            cache.$insertO2Script.empty();
        }
        cache.$insertO2Script.append($('<script></script>').attr('src', mduScript));
    } else {
        $('#o2-unit-select-container').hide('slow');
        $('#o2-unit-input-container').show();
    }
    fillAddressO2Inputs(addressInfo);
}

/**
 * Service call to Geo Code
 * @param {Object} inp updates - fields with updates for account
 * @param {Array} arr response from the service call
 */
function autocomplete(inp, arr) {
    /**
    *JSDoc Comment
    *@param {Object} x isml field
    *
    */
    function removeActive(x) {
        /* a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }
    /**
    *JSDoc Comment
    *@param {Object} x object
    *@returns {Object} object
    */
    function addActive(x) {
        /* a function to classify an item as "active":*/
        if (!x) return false;
        /* start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0; // eslint-disable-line
        if (currentFocus < 0) currentFocus = (x.length - 1); // eslint-disable-line
        /* add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active"); // eslint-disable-line
        return; // eslint-disable-line
    }
    /**
    *JSDoc Comment
    *@param {Object} elmnt updates - fields with updates for account
    */
    function closeAllLists(elmnt) {
        /* close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName('autocomplete-items');
        for (var i = 0; i < x.length; i++) {
            if (elmnt !== x[i] && elmnt !== inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
    var currentFocus; // eslint-disable-line
    /**
     * JSDoc Comment
     * @param {Object} inputbox O2check input text field
     * @returns {Object} object
     */
    function loadValuesToAutoCompleteLists(inputbox) {
        /* Function to populate the Ajax Response to autocomplete dropdown */
        var a;
        var b;
        var i;
        var val = document.getElementById('o2check').value;// eslint-disable-line
        /* close any already open lists of autocompleted values*/
        closeAllLists();
        if (!val) { return false; }
        currentFocus = -1; // eslint-disable-line
        /* create a DIV element that will contain the items (values):*/
        a = document.createElement('DIV');
        a.setAttribute('id', document.getElementById('o2check').id + 'autocomplete-list');
        a.setAttribute('class', 'autocomplete-items');
        /* append the DIV element as a child of the autocomplete container:*/
        document.getElementById('o2check').parentNode.appendChild(a);
        /* for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            /* check if the item starts with the same letters as the text field value:*/
            /* create a DIV element for each matching element:*/
            b = document.createElement('DIV');
            /* make the matching letters bold:*/
            b.innerHTML = '<span class="map-icon"></span> <strong>' + arr[i].address.substr(0, val.length) + '</strong>'; // eslint-disable-line
            b.innerHTML += arr[i].address.substr(val.length);
            b.classList.add('addressSuggestion');
            /* insert a input field that will hold the current array item's value:*/
            arr[i].address = arr[i].address.replace(/'/g, '');
            b.innerHTML += "<input type='hidden' value='" + arr[i].address + "' data-mdu = '" +arr[i].mdu + "' data-addressId = '" + arr[i].addressId + "' data-addressInfo = '" + JSON.stringify(arr[i]) + "'>"; // eslint-disable-line
            /* execute a function when someone clicks on the item value (DIV element):*/
            /* Changed the event to mousedown from click, since click is not firing after bringing in the Focus out event:*/
            b.addEventListener('mousedown', function () {
            /* insert the value for the autocomplete text field:*/
                inputbox.value = this.getElementsByTagName('input')[0].value;
            /* close the list of autocompleted values,
            (or any other open lists of autocompleted values:*/
                updateMDUUnits(this);
                closeAllLists();
            });
            a.appendChild(b);
        }
        return; // eslint-disable-line
    }
    /**
     *execute a function presses a key on the keyboard
    */
    inp.addEventListener('keydown', function (e) {
        var x = document.getElementById(this.id + 'autocomplete-list');
        if (x) x = x.getElementsByTagName('div');
        if (e.keyCode === 40) {
            /* If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++; // eslint-disable-line
            /* and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 38) { // up
            /* If the arrow UP key is pressed, decrease the currentFocus variable:*/
            currentFocus--; // eslint-disable-line
            /* and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 13) {
            /* If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) { // eslint-disable-line
            /* and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click(); // eslint-disable-line
            }
        }
    });

    /* execute a function when someone moves out of Service Address Text Field:*/
    inp.addEventListener('focusout', function () {
        var isInput = false;
        var inputVal = document.getElementById('o2check').value;
        var currentAddress = null;
        if (arr && arr.length) {
            currentAddress = arr.find(addressVal => addressVal.address === inputVal);
            if (currentAddress) {
                isInput = true;
            }
        }
        if (arr.length === 1 || isInput) {
            o2VariantRequest();
        }
    });

    /* execute a function when someone clicks in the document:*/
    document.addEventListener('click', function (e) {
        closeAllLists(e.target);
    });
    /* the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    // var currentFocus; // eslint-disable-line
    /* execute a function when someone writes in the text field:*/
    inp.addEventListener('input', function () { // eslint-disable-line
        loadValuesToAutoCompleteLists(inp);
    });
    loadValuesToAutoCompleteLists(inp);
}
/**
 * o2Request
 */
function o2Request() {
    var address = $('#o2check').val();
    var o2Script = cache.$form.data('typeahead-url') + '?addressesStartsWith=' + address + '&maxResults=10&callback=o2Response';
    if (cache.$insertO2Script.children().length > 0) {
        cache.$insertO2Script.empty();
    }
    cache.$insertO2Script.append($('<script></script>').attr('src', o2Script));
}
/**
 * initO2Request
 */
function initO2Request() {
    var keyNum;
    cache.$o2Input.on('input', function () {
        keyNum = $(this).val().length;
        $('#addressKey').val('');
        // requestMDU(238743625);
        if (keyNum > 2) {
            o2Request();
        }
    });
}

$(document).on('o2Event', function (e) {
    const { detail } = e;
    var addresses = detail.response.lstAddresses;
    var list = [];
    var streetName = '';
    list = addresses.map(function (address) {
        if (!address.street_suffix || address.street_suffix === 'undefined' || address.street_suffix === 'None') {
            streetName = ((!address.pre_directional_code || address.pre_directional_code === 'undefined' || address.pre_directional_code === 'None' ? '' : address.pre_directional_code).trim() + ' ' + (address.street_name).trim() + ' ' + (!address.post_directional_code || address.post_directional_code === 'undefined' || address.post_directional_code === 'None' ? '' : address.post_directional_code).trim()).trim();
        } else {
            streetName = ((!address.pre_directional_code || address.pre_directional_code === 'undefined' || address.pre_directional_code === 'None' ? '' : address.pre_directional_code).trim() + ' ' + (address.street_name).trim() + ' ' + (address.street_suffix).trim() + ' ' + (!address.post_directional_code || address.post_directional_code === 'undefined' || address.post_directional_code === 'None' ? '' : address.post_directional_code).trim()).trim();
        }
        return {
            address: address.full_address_txt,
            mdu: address.mdu_ind,
            addressId: address.address_id,
            addressKey: address.address_key,
            lat: address.latitude,
            long: address.longitude,
            streetNum: address.house_number,
            street: streetName,
            city: address.city,
            stateCode: address.state,
            stateCodeLong: address.state,
            zip: address.postal_code,
            countryCode: 'USA',
            afstreetname: address.street_name,
            afstreetsuffix: address.street_suffix,
            predirection: !address.pre_directional_code || address.pre_directional_code === 'undefined' || address.pre_directional_code === 'None' ? '' : address.pre_directional_code,
            postdirection: !address.post_directional_code || address.post_directional_code === 'undefined' || address.post_directional_code === 'None' ? '' : address.post_directional_code,
            workType: address.work_type,
            geo_type: address.geo_addr_typ,
            propertyId: address.property_Id // TO-DO: PROVIDER ID NEEDS TO BE ADDED ONCE RETURNED IN O2 CACHE
        };
    });
    autocomplete(document.getElementById('o2check'), list);
});

$(document).on('o2EventVariant', function (e) {
    const { detail } = e;
    var addresses = detail.response.lstAddresses;
    var streetName = '';
    var x = document.getElementsByClassName('autocomplete-items');
    for (var i = 0; i < x.length; i++) {
        x[i].parentNode.removeChild(x[i]);
    }
    if (addresses[0]) {
        if (!addresses[0].street_suffix || addresses[0].street_suffix === 'undefined' || addresses[0].street_suffix === 'None') {
            streetName = ((!addresses[0].pre_directional_code || addresses[0].pre_directional_code === 'undefined' || addresses[0].pre_directional_code === 'None' ? '' : addresses[0].pre_directional_code).trim() + ' ' + (addresses[0].street_name).trim() + ' ' + (!addresses[0].post_directional_code || addresses[0].post_directional_code === 'undefined' || addresses[0].post_directional_code === 'None' ? '' : addresses[0].post_directional_code).trim()).trim();
        } else {
            streetName = ((!addresses[0].pre_directional_code || addresses[0].pre_directional_code === 'undefined' || addresses[0].pre_directional_code === 'None' ? '' : addresses[0].pre_directional_code).trim() + ' ' + (addresses[0].street_name).trim() + ' ' + (addresses[0].street_suffix).trim() + ' ' + (!addresses[0].post_directional_code || addresses[0].post_directional_code === 'undefined' || addresses[0].post_directional_code === 'None' ? '' : addresses[0].post_directional_code).trim()).trim();
        }
        var isAddressMDU = addresses[0].mdu_ind;
        $('#lat').val(addresses[0].latitude);
        $('#long').val(addresses[0].longitude);
        $('#streetnumber').val(addresses[0].house_number);
        $('#street').val(streetName);
        $('#city').val(addresses[0].city);
        $('#stateCode').val(addresses[0].state);
        $('#stateCodeLong').val(addresses[0].state);
        $('#zipcode').val(addresses[0].postal_code);
        $('#countrycode').val('USA');
        $('#mdu').val(isAddressMDU);
        $('#addressId').val(addresses[0].address_key);
        $('#addressKey').val(addresses[0].address_key);
        $('#baseAddr').val(addresses[0].address_key);
        $('#predirection').val(!addresses[0].pre_directional_code || addresses[0].pre_directional_code === 'undefined' || addresses[0].pre_directional_code === 'None' ? '' : addresses[0].pre_directional_code);
        $('#postdirection').val(addresses[0].post_directional_code || addresses[0].post_directional_code === 'undefined' || addresses[0].post_directional_code === 'None' ? '' : addresses[0].post_directional_code);
        $('#afstreetname').val(addresses[0].street_name);
        $('#afstreetsuffix').val(addresses[0].street_suffix);
        $('#o2check').val(addresses[0].full_address_txt);
        $('#workType').val(addresses[0].work_type);
        $('#propertyId').val(addresses[0].property_Id);  // TO-DO: PROVIDER ID NEEDS TO BE ADDED ONCE RETURNED IN O2 CACHE
        $('#geo_type').val(addresses[0].geo_addr_typ);
        // TO-DO: PROVIDER ID NEEDS TO BE ADDED ONCE RETURNED IN O2 CACHE
        if (isAddressMDU === 'TRUE') {
            $('#o2-unit-select-container').show();
            $('#o2-unit-input-container').hide('slow');
            // cache.$submit.attr('disabled', true);
            var addressId = addresses[0].address_id;
            var endpoint = cache.$form.data('getmduunits-url');
            var mduScript = endpoint + '?addressId=' + addressId + '&callback=mduResponse';
            if (cache.$insertO2Script.children().length > 0) {
                cache.$insertO2Script.empty();
            }
            cache.$insertO2Script.append($('<script></script>').attr('src', mduScript));
        } else {
            $('#o2-unit-select-container').hide('slow');
            $('#o2-unit-input-container').show();
            // cache.$submit.attr('disabled', false);
        }
    }
});

$(document).on('mduEvent', function (e) {
    const { detail } = e;
    var baseAddr = $('#baseAddr').val();
    var geoAddrTyp = $('#geo_type').val();
    var addressUnits = detail.response.lstOfMDUUnits;
    var mdulat = addressUnits[0].latitude;
    var mdulong = addressUnits[0].longitude;
    var mduList = [];
    var mduAddressAliasList = [];
    var qfBlockedList = [];
    var o2UnitsOptions = $('#o2UnitsOptions');
    for (let i = 0; i < addressUnits.length; i++) {
        mduList[i] = addressUnits[i].fullSecUnitTxt;
        mduAddressAliasList[i] = addressUnits[i].addressAliasId;
        qfBlockedList[i] = addressUnits[i].qf_blocked;
    }
    if ($(o2UnitsOptions).children().length > 1) {
        $(o2UnitsOptions).empty();
    }
    let mdu = {};
    let mdus = [];
    let nounit = {};
    for (let j = 0; j < mduList.length; j++) {
        $(o2UnitsOptions).append($('<option>', {
            id: mduAddressAliasList[j],
            value: mduList[j],
            text: mduList[j]
        }));
        if (j === 0 && geoAddrTyp !== 'NOBASE') {
            nounit = {
                id: 'No-Unit',
                value: baseAddr,
                text: 'No-Unit',
                latitude: mdulat,
                longitude: mdulong,
                qf_blocked: 'No-Unit'
            };
            mdus.push(nounit);
        }
        mdu = { id: mduAddressAliasList[j], value: String(mduList[j]), text: String(mduList[j]), qf_blocked: qfBlockedList[j], latitude: addressUnits[j].latitude, longitude: addressUnits[j].longitude };
        mdus.push(mdu);
    }
    mdulistGlbl = mdus;
    $('#o2UnitsOptions').selectize()[0].selectize.destroy();
    var select = $('#o2UnitsOptions').selectize({
        options: mdus,
        createOnBlur: true,
        openOnFocus: true,
        highlight: true,
        persist: true,
        maxItems: 1,
        labelField: 'text',
        valueField: 'id',
        searchField: ['text'],
        placeholder: 'Please Select Unit',
        render: {
            option: function (item, escape) {
                return '<div class="option" data-qf-blocked="' + escape(item.qf_blocked) + '">' + escape(item.text) + '</div>';
            }
        },
        create: function (input) {
            return {
                id: 'customer_mdu_input',
                value: input,
                text: input
            };
        }
    });

    var selectize = select[0].selectize;
    $('#o2UnitsOptions-selectized').keydown(function () {
        $('#o2unitsKeyedIn').val(1);
    });
    selectize.open();
});

module.exports = {
    initEvents: function () {
        checkFormFieldsFilled.checkFormFieldsFilledAddressChecker(cache.$submit, cache.$form);
        initAutocomplete();
        initO2Request();
        // disableSubmitOnEmptyInput();
        cache.$serviceAddress.on('focus', () => {
            cache.$serviceAddress.attr('autocomplete', 'no');
            cache.$unitNumber.attr('autocomplete', 'no');
        });

        cache.$o2check.on('focus', () => {
            cache.$o2check.attr('autocomplete', 'no');
            cache.$unitNumber.attr('autocomplete', 'no');
        });

        cache.$form.on('change', function () {
            checkFormFieldsFilled.checkFormFieldsFilledAddressChecker(cache.$submit, cache.$form);
        });

        cache.$radioBtns.on('keyup', '.custom-control-input', function () {
            const $this = $(this);
            const wrapper = $this.parents('.bf__agreement');
            wrapper.removeClass('show');

            if ($this.val() === 'true') {
                wrapper.addClass('show');
            }
        });

        cache.$radioBtns.on('click', '.custom-control-label', function () {
            const $this = $(this);
            const wrapper = $this.parents('.bf__agreement');
            wrapper.removeClass('show');

            if ($this.siblings().val() === 'true') {
                wrapper.addClass('show');
            }
        });

        cache.$form.on('submit', submitGeocodedAddress);

        // tracking events
        $('#addresscheck__back').on('click', function () {
            let currentCtlCustomer = $('#centurylink-existing-yes').prop('checked') ? 'yes' : 'no';
            $(this).trigger('addresscheck:action', { btn: 'back', currentCtlCustomer });
        });
        cache.$submit.on('click', function () {
            let currentCtlCustomer = $('#centurylink-existing-yes').prop('checked') ? 'yes' : 'no';
            $(this).trigger('addresscheck:action', { btn: 'check availability', currentCtlCustomer });
        });

        createPopovers();
        prefillAddressFields();
        createCookies();


        // Set default flow name
        if (
            $('#pageInfo').data('isoriginsbg') &&
            $('#pageInfo').data('isoriginsbg') === true
        ) {
            window.localStorage.setItem('flow', 'quantum - sbg');
            window.dispatchEvent(new Event('Flow Name Set'));
        } else if (
            window.localStorage.getItem('flow') !== 'quantum' &&
            document.referrer.indexOf('Instant-ShowWifi') <= -1 &&
            document.referrer.indexOf('Instant-ExpiredGuest') <= -1
        ) {
            window.localStorage.setItem('flow', 'quantum');
            window.dispatchEvent(new Event('Flow Name Set'));
        } else {
            window.dispatchEvent(new Event('Flow Name Set'));
        }
        // o2 address is seleceted from autocomplete move focus to correct input or select
        $(document).on('click', '.addressSuggestion', function () {
            var o2UnitNumber = $('#o2UnitNumber');
            var o2UnitOptions = $('#o2UnitsOptions');
            $(o2UnitNumber).val(''); // clear value
            var isAddressMDU = $(this).find('input').data('mdu');
            if (isAddressMDU === 'FALSE') {
                if ($('#o2-unit-input-container').css('display') === 'block') {
                    $(o2UnitOptions).focus();
                }
            }
        });
         // o2 address is seleceted from autocomplete move focus to Unit select
        $(document).on('change', '#o2UnitsOptions', function () {
            var o2UnitOptionsSelect = $('#o2UnitsOptions option:selected');
            var o2UnitNumber = $('#o2UnitNumber');
            if ($(o2UnitOptionsSelect).text() === "Enter in unit") { // eslint-disable-line
                // cache.$submit.attr('disabled', true);
                $('#o2-unit-select-container').hide();
                $('#o2-unit-input-container').show();
                $(o2UnitNumber).focus();
            }
            var o2UnitOptionId = $(o2UnitOptionsSelect)[0].value;
            var o2unitsValue = $(o2UnitOptionsSelect)[0].innerHTML;
            var addressId = o2UnitOptionId;
            $('#addressId').val(addressId);
            $('#addressKey').val(addressId);
            $('#o2_units').val(o2unitsValue);

            for (var j = 0; j < mdulistGlbl.length; j++) {
                if (addressId === mdulistGlbl[j].id) {
                    $('#lat').val(mdulistGlbl[j].latitude);
                    $('#long').val(mdulistGlbl[j].longitude);
                }
            }

            // check for if keyedin value changed
            if ($('#o2unitsKeyedIn').val() === '1') {
                var findO2Units = o2unitsValue;
                var isUnitFound = mdulistGlbl.find(function (obj) {
                    return obj.value === findO2Units;
                });
                if (isUnitFound) {
                    $('#o2unitsKeyedIn').val('');
                    $('#addressId').val(isUnitFound.id);
                    $('#addressKey').val(isUnitFound.id);
                }
            }
        });
        // if MDU = true and user wants to enter in unit number; grab MDU input and copy over to MDU select option
        $(document).on('click', '#adresscheck-submit', function () {
            var baseAdr = $('#baseAddr').val();
            var o2UnitValue = $('#o2UnitNumber').val();
            if ($('#mdu').val() === 'FALSE' && o2UnitValue && o2UnitValue.trim() !== '' && o2UnitValue.length && o2UnitValue.length > 0) {
                $('#addressId').val('');
                $('#addressKey').val('');
            }
            if ($('#addressId').val() === 'No-Unit' || $('#addressKey').val() === 'No-Unit') {
                $('#addressId').val(baseAdr);
                $('#addressKey').val(baseAdr);
                $('#o2_units').val('');
            }
            if ($('#addressId').val() === 'customer_mdu_input' || $('#addressKey').val() === 'customer_mdu_input') {
                $('#addressId').val('');
                $('#addressKey').val('');
            }
            if ($('#o2unitsKeyedIn').val() === '1') {
                var o2UnitOptionsSelected = $('#o2UnitsOptions option:selected');
                var alphanumericArray = mdulistGlbl.map(function (obj) {
                    return obj.value;
                });
                let numberToFindFirst = $(o2UnitOptionsSelected)[0].innerHTML;
                let associatedElements = alphanumericArray.filter((alphanumericString) => {
                    const matches = alphanumericString.match(/\d+$/);
                    return matches && matches[0] === numberToFindFirst;
                });
                let associatedElement;
                if (associatedElements.length === 1) {
                    associatedElement = associatedElements[0];
                } else {
                    var numberToFind = '0' + numberToFindFirst;
                    associatedElement = alphanumericArray.find((alphanumericString) => {
                        const matches = alphanumericString.match(/\d+$/);
                        return matches && matches[0] === numberToFind;
                    });
                }
                const result = associatedElement || numberToFindFirst;
                if (associatedElement) {
                    var isAddressIdFound = mdulistGlbl.find(function (obj) {
                        return obj.value === associatedElement;
                    });
                    if (isAddressIdFound) {
                        $('#addressId').val(isAddressIdFound.id);
                        $('#addressKey').val(isAddressIdFound.id);
                    }
                }
                var unitSuf = result.split(' ');

                var lastnumber = unitSuf[unitSuf.length - 1];

                if (lastnumber && result.toUpperCase() !== 'NO-UNIT') {
                    var samesuff = [];
                    var addresaliasid = [];

                    for (var indx = 0; indx < mdulistGlbl.length; indx++) {
                        var lastletter = mdulistGlbl[indx].text.split(' ');

                        if (lastnumber === lastletter[lastletter.length - 1]) {
                            samesuff.push(mdulistGlbl[indx].text);
                            addresaliasid.push(mdulistGlbl[indx].id);
                        }
                    }
                    if (samesuff && samesuff[0] && samesuff.length === 1) {
                        $('#o2_units').val(samesuff[0]);
                        $('#addressId').val(addresaliasid[0]);
                        $('#addressKey').val(addresaliasid[0]);
                    }
                } else if (result.toUpperCase() === 'NO-UNIT') {
                    $('#addressId').val(baseAdr);
                    $('#addressKey').val(baseAdr);
                    $('#o2_units').val('');
                } else {
                    $('#o2_units').val(result);
                }
            }
            // e.preventDefault();
            var o2UnitOptionsSelect = $('#o2UnitsOptions option:selected');
            var o2UnitNumber = $('#o2UnitNumber');
            if ($(o2UnitOptionsSelect).text() === "Enter in unit") { // eslint-disable-line
                var o2UnitNumberValue = $(o2UnitNumber).val(); // grab input value
                $(o2UnitOptionsSelect).val(o2UnitNumberValue); // Popluate select from input
                $(o2UnitNumber).val(''); // clear value so it doesn't duplicate down stream
            }
        });
        $(document).on('input', '#js-radio-btns-wrapper input, #o2check, #o2UnitsOptions', function () {
            // debugger;
            // console.log()
            if ((($('#centurylink-existing-yes').is(':checked') || $('#centurylink-existing-no').is(':checked')) && $('#o2check').val().length > 2 && $('#o2UnitsOptions').text() !== 'Enter in unit' && $('#o2UnitsOptions').val().length > 0) || ($('#o2check').val().length > 2 && $('#o2check').data('transfer'))) {
                $('#adresscheck-submit').attr('disabled', false);
            } else {
                $('#adresscheck-submit').attr('disabled', true);
            }
            if (($('#centurylink-existing-yes').is(':checked') || $('#centurylink-existing-no').is(':checked')) && ($('#mdu').val() === 'FALSE' || $('#mdu').val() === '')) {
                $('#adresscheck-submit').attr('disabled', false);
            }
        });
        $(document).on('change', '#js-radio-btns-wrapper input, #o2check, #o2UnitsOptions', function () {
            // debugger;
            if ((($('#centurylink-existing-yes').is(':checked') || $('#centurylink-existing-no').is(':checked')) && $('#o2check').val().length > 2 && $('#o2UnitsOptions').text() !== 'Enter in unit' && $('#o2UnitsOptions').val().length > 0) || ($('#o2check').val().length > 2 && $('#o2check').data('transfer'))) {
                $('#adresscheck-submit').attr('disabled', false);
            } else {
                $('#adresscheck-submit').attr('disabled', true);
            }
            if (($('#centurylink-existing-yes').is(':checked') || $('#centurylink-existing-no').is(':checked')) && ($('#mdu').val() === 'FALSE' || $('#mdu').val() === '')) {
                $('#adresscheck-submit').attr('disabled', false);
            }
        });
        $(document).ready(function () {
            if ($('#o2check').val().length > 2 && $('#o2check').data('transfer')) {
                $('#adresscheck-submit').attr('disabled', false);
            } else {
                $('#adresscheck-submit').attr('disabled', true);
            }
        });
    }
};
