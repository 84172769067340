'use strict';

let pageData = $('#pageInfo').data('page-info');
let category = pageData ? pageData.category : '';
let actionField = getActionField(); // eslint-disable-line

/**
 * Returns a function to be used as callback
 * @param {string} eventName event name
 * @param {string} data button name
 * @returns {Function} event triggering function
*/
function triggerEvent(eventName, data) {
    return () => $(document).trigger(eventName, data);
}
/**
 * Builds product impressions array used for tracking
 * @param {string} selector selector for html element
 * @returns {Array} product objects
 */
function getProductImpressions(selector) {
    let loopQualStatus = $('#bf-address-smartnid').text();
    return $(selector).get().map((prod) => {
        let $prod = $(prod);
        return {
            name: $prod.data('pname'),
            price: $prod.data('price'),
            category: category,
            variant: $prod.data('variant'),
            position: $prod.index(),
            dimension97: loopQualStatus,
            dimension98: (loopQualStatus == null || (loopQualStatus === 'SmartNID enabled' && Number($('#bf-address-downloadspeed').text()) < Number($prod.data('minspeed'))) || loopQualStatus === 'SmartNID eligible')
        };
    });
}
/**
 * Builds product impressions array used for tracking
 * @param {string} selector selector for html element
 * @returns {Array} product objects
 */
function getMoreDetailsImpressions(selector) {
    return $(selector).get().map((prod) => {
        let $prod = $(prod);

        return {
            name: $prod.data('pname'),
            list: actionField,
            price: $prod.data('price'),
            brand: null,
            variant: $prod.data('variant')
        };
    });
}

/**
 * list value required for tracking
 * @returns {string} list
 */
function getActionField() {
    let listMapping = {
        InternetPlan: 'fiber internet',
        Modem: 'modem options',
        AddonProducts: 'addons'
    };
    return listMapping[category];
}

/**
 * Builds product impressions array used for tracking
 * @param {string} selector selector for html element
 * @returns {Array} product objects
 */
function getAllProductDetails(selector) {
    return $(selector).get().map((prod) => {
        let $prod = $(prod);
        return {
            name: $prod.data('pname'),
            price: $prod.data('price'),
            category: category
        };
    });
}

module.exports = {
    triggerEvent,
    getProductImpressions,
    getActionField,
    getMoreDetailsImpressions,
    getAllProductDetails
};
